@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'GoodDog';
  src: url('/public/fonts/GOODDP__.TTF') format('truetype');
  /* Add additional src for other formats if available */
}
@font-face {
  font-family: 'Comic Sans MS';
  src: url('/public/fonts/ComicSansMS3.ttf') format('truetype');
  /* Add additional src for other formats if available */
  font-weight: bold; /* Specify bold weight */
}
@font-face {
  font-family: 'Comic Sans';
  src: url('/public/fonts/6692-fontps.ttf') format('truetype');
  /* Add additional src for other formats if available */
}
.font1{
  font-family: 'GoodDog';
}
.font2{
  font-family: 'Comic Sans MS';
}
.font3{
  font-family: 'Comic Sans';
}
.shadows {
  text-align: center;
  text-transform: uppercase;
  text-shadow: 
  0px 0px 60px #FF0000,
  0px 0px 30px #F78113,
  0px 0px 15px #FDBE23,
  0px 0px 5px #FFCE56;
  
}

.my-bg{
  background-image: url(/public/some.png);
}
.my-bg1{
  background-image: url(/public/Hero-1.png);
}
@media (max-width:600px) {
  .my-bg{
    background-image: url(/public/some1.png);
  }
  .my-bg1{
    background-image: url(/public/BG\ \(3\).png);
  }
}